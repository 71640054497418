.filter {
  padding-bottom: 10px;
}

.filter-heading {
  display: flex;
  align-items: center;

  * {
    @include themify($themes) {
      color: themed("headingColor");
    }
    font-size: $font-size-base;
  }

  button {
    @extend .no-btn;
  }

  h3,
  button.filter-button {
    margin: 0;
    @include themify($themes) {
      color: themed("textMuted");
    }
  }

  .filter-button {
    h3,
    button {
      &:hover,
      &:focus,
      &.active {
        @include themify($themes) {
          color: themed("linkColor") !important;
        }
      }
      &.active {
        font-weight: bold;
      }
    }
  }

  button.toggle-button,
  button.add-button {
    margin-right: 0.25rem;
    &:hover,
    &:focus {
      @include themify($themes) {
        color: themed("iconHover") !important;
      }
    }
  }
}

.filter-options {
  padding: 0;
  list-style: none;
  margin: 0 0 15px 0;
  .nested-filter-options {
    list-style: none;
    margin-bottom: 0px;
    padding-left: 0.85em;
  }
}

.filter-option {
  @include themify($themes) {
    color: themed("textColor");
  }

  &.active {
    > .filter-buttons {
      .filter-button {
        @include themify($themes) {
          color: themed("linkColor") !important;
        }
        font-weight: bold;
      }

      .edit-button {
        visibility: visible;
      }
    }
  }
}

.filter-buttons {
  display: flex;
  align-items: center;

  button,
  a {
    @extend .no-btn;
  }

  .contents {
    display: contents;
  }

  .toggle-button {
    margin-right: 0.25rem;
    &:hover,
    &:focus {
      @include themify($themes) {
        color: themed("iconHover") !important;
      }
    }
  }

  .filter-button {
    max-width: 90%;
    white-space: nowrap;

    &:hover,
    &:focus,
    &:active {
      @include themify($themes) {
        color: themed("linkColor") !important;
      }
      text-decoration: none;
    }

    &.disabled-organization {
      max-width: 78%;
    }
  }

  .edit-button {
    visibility: hidden;
    margin-left: auto;

    @include themify($themes) {
      color: themed("headingButtonColor");
    }

    &:hover,
    &:focus {
      @include themify($themes) {
        color: themed("iconHover") !important;
      }
    }
  }

  .filter-options-icon {
    padding: 0 2px;
  }
}

.filter {
  ul:last-child {
    margin-bottom: 0;
  }

  a {
    @include themify($themes) {
      color: themed("textHeadingColor");
    }
  }
  .show-active {
    display: none;
  }
  li.active {
    > .show-active,
    > div .show-active {
      display: inline;
    }
  }
  li.active {
    > button:first-of-type,
    > div button:first-of-type {
      @include themify($themes) {
        color: themed("linkColor");
      }
    }

    > .bwi,
    > div > .bwi {
      @include themify($themes) {
        color: themed("linkColor");
      }
    }
  }
}
